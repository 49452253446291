import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hi-there-"
    }}>{`Hi there! 👋`}</h1>
    <p>{`Let me just start off by congratulating you on making it on the team, we're really glad to have you here! 🥳`}<br parentName="p"></br>{`
`}{`Starting in a new position, anywhere, is an exciting endeavor, but it can also be filled with anxiety and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Impostor_syndrome"
      }}>{`impostor syndrome`}</a>{` - Which is OK 💆‍♂️.`}</p>
    <p>{`This guide aims to get you up to speed without getting too technical. 🚀`}</p>
    <p>{`Here's a short overview of what we'll be covering in this guide:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension"
        }}>{`Creating your first app`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/publish-to-devops"
        }}>{`Storing your app on Azure DevOps`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/devops"
        }}>{`Collaborating with others on Azure DevOps`}</a></li>
    </ol>
    <p>{`But first, let's take a quick look at what we're doing here, and the system you'll be spending most of your time working with. 🧑‍🏭`}</p>
    <Navigation next="/introduction/what-is-erp" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      